import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import Loading from './components/Loading/Loading'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

const Contact = React.lazy(() => import('./views/Contact'))
const Error404 = React.lazy(() => import('./views/Error404'))
const Home = React.lazy(() => import('./views/Home'))
const Resume = React.lazy(() => import('./views/Resume'))
//import { initializeApp } from 'firebase/app'

//const firebaseConfig = {
//  apiKey: 'AIzaSyBOc4uW9zAPCivHNibqAeQWH-7Q6AeZj1I',
//  authDomain: 'luiz-mainart-dev.firebaseapp.com',
//  projectId: 'luiz-mainart-dev',
//  storageBucket: 'luiz-mainart-dev.appspot.com',
//  messagingSenderId: '517119638501',
//  appId: '1:517119638501:web:b1c0f64c1db310f807cd0c',
//  measurementId: 'G-JG84THMT3G',
//}

// const firebase = initializeApp(firebaseConfig)

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="*" element={<Error404 />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
