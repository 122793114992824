import { Link } from 'react-router-dom'
import logo_white from '../../static/logo-white.svg'
import { CONTACT_EMAIL } from '../../utils/variables'
import styles from './Footer.module.scss'

export default function Footer() {
  return (
    <div>
      <div className="spacer"></div>
      <footer className={'footer ' + styles.footer}>
        <div className="content has-text-centered has-text-white">
          <img src={logo_white} alt="Luiz Mainart Logo" />
          <h1 className="title is-1 has-text-white">Let's talk!</h1>
          <p className="is-size-4">
            I am always open to discuss your project or talk about ways <br />
            to help nice companies.
          </p>
          <div className="buttons is-justify-content-center">
            <a
              href="https://www.linkedin.com/in/luiz-mainart/"
              target="_blank"
              rel="noopener noreferrer"
              className="button is-secondary is-large mt-5 is-rounded"
            >
              <span className="icon is-large">
                <i className="mdi mdi-linkedin mdi-36px" />
              </span>
            </a>
            <a
              href="https://twitter.com/LuizDMM"
              target="_blank"
              rel="noopener noreferrer"
              className="button is-secondary is-large mt-5 is-rounded"
            >
              <span className="icon is-large">
                <i className="mdi mdi-twitter mdi-36px" />
              </span>
            </a>
            <a
              href="mailto:{CONTACT_EMAIL}"
              target="_blank"
              rel="noopener noreferrer"
              className="button is-secondary is-large mt-5 is-rounded"
            >
              <span className="icon is-large">
                <i className="mdi mdi-email mdi-36px" />
              </span>
            </a>
          </div>
          <div className="container">
            <div className="columns mt-6">
              <div className="column">
                <nav className="navbar is-primary">
                  <div className="navbar-menu">
                    <div className="navbar-start">
                      <Link to="/" className="navbar-item">
                        Home
                      </Link>
                      <Link to="/portfolio" className="navbar-item">
                        Portfolio
                      </Link>
                      <Link to="/contact" className="navbar-item">
                        Contact
                      </Link>
                      <Link to="/resume" className="navbar-item">
                        Resume
                      </Link>
                    </div>
                  </div>
                </nav>
              </div>
              <div className="column has-text-right">
                <p>{CONTACT_EMAIL}</p>
              </div>
            </div>
          </div>

          <p className="is-size-6 has-text-weight-light">
            Copyright © {new Date().getFullYear()} by Luiz D. M. Mainart. All
            Rights Reserved. <br />
            Designed and implemented by me from my home :)
          </p>
        </div>
      </footer>
    </div>
  )
}
