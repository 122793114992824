import Lottie from 'lottie-react'
import loading from '../../static/lotties/loading.json'
import styles from './Loading.module.scss'

export default function Loading() {
  return (
    <section className={styles.loading_section}>
      <div className={styles.loading_animation}>
        <Lottie animationData={loading} loop={true} autoPlay={true} />
      </div>
    </section>
  )
}
