import { Link, NavLink } from 'react-router-dom'
import logo from '../../static/logo.svg'
import { BLOG_URL, BOOKING_URL } from '../../utils/variables'
import styles from './Navbar.module.scss'

export default function Navbar() {
  return (
    <nav
      className="navbar is-spaced"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <img src={logo} id={styles.logo} alt="Luiz Mainart" />
          </Link>
          <button
            className="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarContent"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </button>
        </div>
        <div id="navbarContent" className="navbar-menu">
          <div className={'navbar-start ' + styles.centered}>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? 'is-active navbar-item is-tab' : 'navbar-item is-tab'
              }
            >
              Home
            </NavLink>
            <NavLink
              to="/portfolio"
              className={({ isActive }) =>
                isActive ? 'is-active navbar-item is-tab' : 'navbar-item is-tab'
              }
            >
              Portfolio
            </NavLink>
            <NavLink
              to="/contact"
              className={({ isActive }) =>
                isActive ? 'is-active navbar-item is-tab' : 'navbar-item is-tab'
              }
            >
              Contact me
            </NavLink>
            <NavLink
              to="/resume"
              className={({ isActive }) =>
                isActive ? 'is-active navbar-item is-tab' : 'navbar-item is-tab'
              }
            >
              Resume
            </NavLink>
            <a href={BLOG_URL} className="navbar-item is-tab">
              Blog
            </a>
          </div>
          <div className="navbar-end">
            <div className="navbar-item">
              <a href={BOOKING_URL} className="button is-primary">
                Book a Call
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}
